import React from 'react'
import { Global, css } from '@emotion/react'

export const breakpoints = {
	mobile: '@media (max-width: 768px)',
	nav: '@media (max-width: 768px)',
	tablet: '@media (max-width: 1024px)',
	desktop: '@media (max-width: 1230px)',
	desktopUp: '@media (min-width: 769px)',
}

export const mobile = breakpoints.mobile
export const navBreakpoint = breakpoints.nav
export const tablet = breakpoints.tablet
export const desktop = breakpoints.desktop
export const desktopUp = breakpoints.desktopUp

const GlobalStyles = () => (
	<>
		<Global styles={reset} />
		<Global styles={styles} />
	</>
)

const styles = css`
	html {
		scroll-padding-top: 67px;
		width: 100vw;
		overflow-x: hidden;

		${mobile} {
			scroll-padding-top: 60px;
		}
	}

	body {
		width: 100vw;
		overflow-x: hidden;
		font-family: "Heldane", Garamond, serif;
		font-size: 24px;
		line-height: 1.25;
		// background: rgb(255, 255, 255);
		// background: linear-gradient(
		// 	180deg,
		// 	rgba(255, 255, 255, 1) 50%,
		// 	rgba(0, 0, 0, 1) 50%
		// );

		${tablet} {
			font-size: 20px;
			line-height: 1.3;
		}
	}

	:root {
		--white: #ffffff;
		--black: #000000;
		--blue: #51c7d4;
		--teal: #075366;
		--orange: #ff8b59;
		--orange-pale: #fff6ed;
		--grey: #f2f2f2;

		--serif: Heldane, Garamond, serif;
		--sans: Relative, sans-serif;

		--p-16: 16px;
		--gap: 20px;
		--margin: 40px;
		--span-all: 1/-1;

		--h1-size: 55px;
		--h1-line: 65px;

		--serif-lrg-size: 48px;
		--serif-lrg-line: 57px;

		--serif-xl-size: 64px;
		--serif-xl-line: 1;

		--serif-body-size: 24px;
		--serif-body-line: 1.25;

		--sans-accent-lrg-size: 16px;
		--sans-accent-lrg-line: 1.25;

		--sans-accent-size: 16px;
		--sans-accent-line: 1.25;

		--marquee-size: 24px;
		--marquee-line: 1;

		--letter-spacing: 0.1em;
		--transition-300: 300ms ease-in-out;

		--heading-pb: 9px;
		--heading-border: 12px;

		--header-height: 67px;

		// --xxs: 5px;
		// --xs: 10px;
		// --s: 20px;
		// --m: 30px;
		// --l: 40px;
		// --xl: 50px;
		// --xxl: 80px;
		// --xxxl: 100px;
		// --xxxxl: 150px;
	}

	${tablet} {
		:root {
			--h1-size: 35px;
			--h1-line: 40px;

			--serif-lrg-size: 30px;
			--serif-lrg-line: 36px;

			--serif-xl-size: 35px;
			--serif-xl-line: 40px;

			--serif-body-size: 20px;
			--serif-body-line: 1.3;

			--sans-accent-lrg-size: 16px;
			--sans-accent-lrg-line: 1.3;

			--sans-accent-size: 12px;
			--sans-accent-line: 1.25;

			--heading-pb: 8px;
			--heading-border: 8px;

			--header-height: 60px;

			// --xxs: 3px;
			// --xs: 7px;
			// --s: 15px;
			// --m: 20px;
			// --l: 30px;
			// --xl: 35px;
			// --xxl: 40px;
			// --xxxl: 60px;
			// --xxxxl: 120px;
		}
	}

	${mobile} {
		:root {
			--margin: 20px;
		}
	}

	p {
		// margin-bottom: 0;
		// &:last-child {
		// 	margin-bottom: 0;
		// }
	}

	h1,
	.h1,
	h2,
	.h2 {
		font-family: var(--sans);
		font-weight: 700;
		font-size: var(--h1-size);
		line-height: var(--h1-line);
		letter-spacing: 0.007em;
	}

	h3,
	.h3 {
		font-size: var(--serif-xl-size);
		line-height: var(--serif-xl-size);
	}
`
const reset = css`
	body {
		margin: 0px;
		min-height: 100%;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: inherit;
		font-size: inherit;
	}

	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
		padding: 0;
	}

	ul,
	ol,
	blockquote {
		margin: 0;
		padding: 0;
	}

	li {
		margin: 0.5em 0;
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	* {
		// background-color: rgb(81 199 212 / 0.05);
		box-sizing: border-box;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		/*  This prevents chrome from annoyingly adjusting the scroll to try and compensate for moving elements */
		overflow-anchor: none;
	}

	button {
		-webkit-appearance: none;
		border: none;
		background: none;
		font-size: inherit;
		font-family: inherit;
		cursor: pointer;
		padding: 0;
		margin: 0;
	}

	input,
	select {
		border-radius: 0;
		-webkit-appearance: none;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border: none;
		-webkit-text-fill-color: inherit;
		-webkit-box-shadow: 0 0 0px 0px transparent inset;
		transition: background-color 5000000s ease-in-out 0s;
	}

	/* Prevent safari blue links */
	button,
	option {
		color: inherit;
	}

	.video-react .video-react-poster{
		background-color: transparent !important;
	}

`

export default GlobalStyles
