import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import styled from '@emotion/styled'
import { LogoRGB } from '~components/Svg'
import Hamburger from './Hamburger'
import { useState } from 'react'
import { useLocation } from '@reach/router'

import {
	mobile,
	navBreakpoint,
	tablet,
	desktop,
	desktopUp,
} from '../styles/global'
import { motion } from 'framer-motion'

const Header = ({ className }) => {
	const [menuOpen, setMenuOpen] = useState(false)
	const location = useLocation()

	useEffect(() => {
		setMenuOpen(false)
	}, [location])

	const container = {
		closed: {
			opacity: 0,
			height: 0,
			padding: 0,
			// display: "none",
			transition: {
				duration: 0.25,
			},
		},
		open: {
			display: 'flex',
			height: 'fit-content',
			opacity: 1,
			paddingTop: '26px',
			paddingBottom: '36px',
			transition: {
				type: 'tween',
				delayChildren: 0.5,
				staggerChildren: 0.2,
			},
		},
	}

	const item = {
		closed: {
			y: 5,
			opacity: 0,
			transition: { type: 'tween', duration: 0.25 },
		},
		open: {
			y: 0,
			opacity: 1,
			transition: { type: 'tween', duration: 0.25 },
		},
	}

	return (
		<Wrap className={className}>
			<div>
				<HomeLink to="/">
					<HeaderLogo />
				</HomeLink>
				<NavDesktop>
					<Link to="/#services">Services</Link>
					<Link to="/#stories">Stories</Link>
					<Link to="/portfolio">Full Portfolio</Link>
					<Link to="/about">About</Link>
					<Link to="/#contact">Contact</Link>
				</NavDesktop>
				<Hamburger menuOpen={menuOpen} setMenuOpen={setMenuOpen}></Hamburger>
			</div>
			<NavMobile
				layout
				initial={false}
				variants={container}
				animate={menuOpen ? 'open' : 'closed'}
				menuOpen={menuOpen}
			>
				<motion.div variants={item}>
					<Link to="/#services">Services</Link>
				</motion.div>
				<motion.div variants={item}>
					<Link to="/#stories">Stories</Link>
				</motion.div>
				<motion.div variants={item}>
					<Link to="/portfolio">Portfolio</Link>
				</motion.div>
				<motion.div variants={item}>
					<Link to="/about">About</Link>
				</motion.div>
				<motion.div variants={item}>
					<Link to="/#contact">Contact</Link>
				</motion.div>
			</NavMobile>
		</Wrap>
	)
}

const Wrap = styled.header`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	display: flex;
	flex-direction: column;
	// gap: 35px;
	padding: 15px var(--margin) var(--p-16);
	border-top: 12px solid var(--blue);
	background: rgba(255, 255, 255, 0.9);
	backdrop-filter: blur(5px);
	transition: border var(--transition-300);

	${navBreakpoint} {
		border-top: 8px solid var(--blue);
		padding-top: 12px;
		padding-bottom: 0;
		// height: 60px;
	}

	> div {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 30px;

		${mobile} {
			padding-bottom: 16px;
		}
	}
`

const HomeLink = styled(Link)`
	height: 24px;
`

const HeaderLogo = styled(LogoRGB)`
	width: 252px;
	height: 24px;

	${mobile} {
		width: 258px;
		height: 28px;
	}
`

const NavDesktop = styled.nav`
	display: flex;
	gap: 30px;
	font-family: var(--sans);
	font-size: var(--marquee-size);
	line-height: var(--marquee-line);
	letter-spacing: 0.1em;
	font-weight: 700;
	text-transform: uppercase;
	transition: gap var(--transition-300);

	@media(max-width: 1100px) {
		font-size: var(--sans-accent-size);
		line-height: var(--sans-accent-line);
	}

	${tablet} {
		gap: 20px;
	}

	${navBreakpoint} {
		display: none;
	}
`

const NavMobile = styled(motion.nav)`
	display: none !important;
	// background: rgba(255, 255, 255, 0.9);
	// backdrop-filter: blur(5px);

	${navBreakpoint} {
		pointer-events: ${(props) => (props.menuOpen ? 'all' : 'none')};
		// z-index: 100;
		// position: fixed;
		// left: 0;
		// top: 100%;
		// right: 0;
		// width: 100%;
		// transform: translateY(100%);
		// transform-origin: top;
		overflow-y: hidden;
		display: flex !important;
		flex-direction: column;
		gap: 17px;
		font-family: var(--sans);
		font-size: var(--h1-size);
		line-height: 1;
	}
`

Header.propTypes = {
	className: PropTypes.string,
}

export default Header
