import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import GlobalStyles from "~styles/global";
import Header from "~components/Header";
import Footer from "~components/Footer";
import smoothscroll from "smoothscroll-polyfill";
import { mobile } from "~styles/global";
import Transition from "~components/Transition";
import { useSiteState } from "../context/siteContext";
import styled from "@emotion/styled";
import { useLocation } from "@reach/router";
import "~styles/static.css";
import { navBreakpoint } from "../styles/global";

const Layout = ({ children }) => {
	const [siteState, setSiteState] = useSiteState();
	const location = useLocation();

	useEffect(() => {
		smoothscroll.polyfill();
	}, []);

	// You can use the below function to close the menu whenenver the location changes
	useEffect(() => {
		// eslint-disable-next-line
	}, [location]);

	return (
		<>
			<GlobalStyles />
			<Header />
			<main>
				<Transition>
					<div
						css={css`
							min-height: calc(100vh);
							display: flex;
							flex-direction: column;
							padding-top: 67px;
							${mobile} {
								padding-top: 60px;
								min-height: calc(100vh);
							}
						`}
					>
						{children}
						<Footer
							css={css`
								margin-top: auto;
							`}
						/>
					</div>
				</Transition>
			</main>
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node,
};

export default Layout;
