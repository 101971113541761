import React from "react";
import PropTypes from "prop-types";
import resolveLink from "~utils/resolveLink";
import { Link } from "gatsby";
import { css } from "@emotion/react";
import BlockContent from "@sanity/block-content-to-react";
import { mobile, navBreakpoint, tablet, desktop } from "../styles/global";

const serializers = {
	marks: {
		internalLink: (props) => {
			return <Link to={resolveLink(props.mark.page)}>{props.children}</Link>;
		},
		link: (props) => {
			if (props.mark.linkType === "internal") {
				return (
					<Link to={resolveLink(props.mark.document)}>{props.children}</Link>
				);
			} else {
				return (
					<a
						href={props.mark?.url}
						target={props.mark.blank ? "_blank" : "_self"}
						rel="noreferrer"
					>
						{props.children}
					</a>
				);
			}
		},
		uline: (props) => {
			return (
				<>
					{/* {props.children[0].split(" ").map((letter, i) => (
						{i === 0 ? letter : " " + letter}
					))} */}
					<span
						css={css`
							font-style: normal;
							position: relative;
							padding-bottom: 2px;
							background: rgb(255, 255, 255);
							background: linear-gradient(
								180deg,
								rgba(255, 255, 255, 0) 82%,
								rgba(81, 199, 212, 1) 82%,
								rgba(81, 199, 212, 1) 99.5%,
								rgba(255, 255, 255, 0) 99.5%
							);

							${tablet} {
								padding-bottom: 2px;
								background: linear-gradient(
									180deg,
									rgba(255, 255, 255, 0) 79.5%,
									rgba(81, 199, 212, 1) 79.5%,
									rgba(81, 199, 212, 1) 98%,
									rgba(255, 255, 255, 0) 98%
								);
							}

							// display: inline-block;
							// text-decoration: underline var(--blue) 12px;
							// padding-bottom: -1px;
							// border-bottom: var(--heading-border) solid var(--orange);
							// 	var(--heading-border) inset var(--teal);
							// box-shadow: 0 var(--heading-border) 0 var(--orange);

							&:not(:last-of-type) {
								// padding-right: 13px;
							}

							&::after {
								// content: "";
								// position: absolute;
								// bottom: -1px;
								// left: 0;
								// height: 12px;
								// width: 100%;
								// background-color: var(--orange);
								// z-index: -1;

								${tablet} {
									// height: 10px;
									// bottom: -3px;
								}
							}
						`}
						className="uline"
					>
						{props.children}
					</span>
				</>
			);
		},
	},
};

const RichText = ({ content }) => (
	<BlockContent
		blocks={content}
		serializers={serializers}
		ignoreUnknownTypes={true}
	/>
);

RichText.propTypes = {
	content: PropTypes.array,
};

export default RichText;
