module.exports = {
	siteUrl: 'https://positivevision.gatsbyjs.io',
	buildsUrl: 'https://positivevision.gtsb.io',
	previewUrl: 'https://preview-positivevision.gtsb.io',
	previewWebhook: 'https://webhook.gatsbyjs.com/hooks/data_source/9f8e3419-ab17-45df-9bdc-7a8163478acd',
	mailchimpUrl: 'https://work.us20.list-manage.com',
	mailchimpUser: 'b2b9468ba5d766d69fd543e0e',
	mailchimpId: '5226532c72',
	gTag: 'G-XXXXXXXXX'
}
