import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import { Link, navigate } from 'gatsby'
import styled from '@emotion/styled'
import Section from '~components/Section'
import useSiteSettings from '~utils/useSiteSettings'
import RichText from '~components/RichText'
import { LogoWhite } from '~components/Svg'
import { mobile, navBreakpoint, tablet, desktop } from '../styles/global'
import SanityLink from '~components/SanityLink'
import { motion } from 'framer-motion'

const Footer = ({ className }) => {
	const { footerText, linkedInUrl, twitterUrl } = useSiteSettings()
	return (
		<Wrap className={className} id="contact">
			<FooterText>
				<RichText content={footerText} />
			</FooterText>
			<FooterBottom>
				<FooterBottomInner>
					<FooterHomeLink to="/">
						<FooterLogo />
					</FooterHomeLink>
					<FooterNav>
						<div>
							<Link to="portfolio">Portfolio</Link>
							{/* <SanityLink link={{ linkType: "external", url: twitterUrl }}>
								Twitter
							</SanityLink> */}
							<SanityLink link={{ linkType: 'external', url: linkedInUrl }}>
								LinkedIn
							</SanityLink>
						</div>
						<BackToTop
							onClick={() =>
								window.scrollTo({
									top: 0,
									left: 0,
									behavior: 'smooth',
								})
							}
						>
							Back to top
						</BackToTop>
					</FooterNav>
				</FooterBottomInner>
			</FooterBottom>
		</Wrap>
	)
}

const Wrap = styled.footer`
	padding-top: 37px;
	padding-bottom: 32px;
	background-color: var(--black);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 180px;

	${mobile} {
		min-height: 496px;
		gap: 160px;
		padding-top: 33px;
		padding-bottom: 28px;
	}
`

const FooterText = styled(Section)`
	& * {
		grid-column: 1 / span 9;
	}

	a:hover {
		text-decoration: underline;
	}

	p {
		font-size: var(--serif-lrg-size);
		line-height: var(--serif-lrg-line);
		color: var(--white);

		${navBreakpoint} {
			font-size: 28px;
			line-height: 33.4px;
		}
	}
`

const FooterBottom = styled(Section)``

const FooterBottomInner = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 32px;
	border-top: 1px solid var(--white);
	padding-top: 32px;
	grid-column: var(--span-all);

	${navBreakpoint} {
		flex-direction: column;
	}
`

const FooterHomeLink = styled(Link)`
	height: 26px;
`

const FooterLogo = styled(LogoWhite)`
	width: 253px;
	height: 26px;

	${navBreakpoint} {
		width: 258px;
		height: 28px;
	}
`

const FooterNav = styled.nav`
	display: flex;
	gap: 28px;

	div {
		display: flex;
		gap: 28px;
	}

	${navBreakpoint} {
		align-items: flex-end;
		justify-content: space-between;

		div {
			flex-direction: column;
			gap: 12px;
		}
	}

	* {
		color: var(--white);
		font-family: var(--sans);
		font-size: var(--sans-accent-lrg-size);
		line-height: var(--sans-accent-lrg-line);
		text-transform: uppercase;
		letter-spacing: 0.1em;
	}
`

const BackToTop = styled.button`
	height: fit-content;
	width: fit-content;
	justify-self: flex-start;
`

Footer.propTypes = {
	className: PropTypes.string,
}

export default Footer
