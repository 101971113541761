/* eslint-disable react/prop-types */
import React from "react";

export const Face = ({ className }) => (
	<svg
		className={className}
		viewBox="0 0 99 106"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="20" cy="20" r="20" fill="currentColor" />
		<path
			d="M49.5 105.5C76.8381 105.5 99 83.3381 99 56H0C0 83.3381 22.1619 105.5 49.5 105.5Z"
			fill="currentColor"
		/>
		<circle cx="79" cy="20" r="20" fill="currentColor" />
	</svg>
);

export const LogoRGB = ({ className }) => (
	<svg
		className={className}
		viewBox="0 0 252 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_20_1061)">
			<path
				d="M17.6144 18.7947H17.3158C16.0355 19.7012 14.5237 20.2443 12.9472 20.3642C11.3707 20.4841 9.79103 20.176 8.38255 19.4739C6.97408 18.7718 5.79164 17.7031 4.96584 16.3858C4.14004 15.0686 3.703 13.5539 3.703 12.0092C3.703 10.4645 4.14004 8.94991 4.96584 7.63261C5.79164 6.31532 6.97408 5.24663 8.38255 4.54457C9.79103 3.8425 11.3707 3.53439 12.9472 3.65426C14.5237 3.77413 16.0355 4.3173 17.3158 5.22381H17.6144C19.4586 5.22381 21.2272 5.93949 22.5312 7.21338C23.8353 8.48728 24.5679 10.2151 24.5679 12.0166C24.5686 9.64257 23.8486 7.32162 22.4989 5.34736C21.1492 3.3731 19.2305 1.83422 16.9854 0.92537C14.7403 0.0165214 12.2698 -0.221459 9.88621 0.241517C7.50265 0.704494 5.31318 1.84763 3.59476 3.52634C1.87634 5.20505 0.706156 7.34391 0.232227 9.67239C-0.241701 12.0009 0.00191437 14.4143 0.932264 16.6075C1.86261 18.8007 3.4379 20.6751 5.45886 21.9936C7.47982 23.3121 9.85567 24.0155 12.2859 24.0148C15.543 24.0138 18.6664 22.7494 20.9695 20.4995C23.2726 18.2496 24.5669 15.1984 24.5679 12.0166C24.5639 13.8156 23.8295 15.5396 22.5259 16.8103C21.2223 18.081 19.456 18.7947 17.6144 18.7947Z"
				fill="url(#paint0_radial_20_1061)"
			/>
			<path
				d="M17.6143 5.20905H17.3157C18.3909 5.96977 19.2706 6.96388 19.8857 8.11314C20.5008 9.26239 20.8345 10.5354 20.8605 11.832C20.8605 11.8837 20.8605 11.9391 20.8605 11.9945C20.8605 12.0498 20.8605 12.1052 20.8605 12.1569C20.8345 13.4535 20.5008 14.7265 19.8857 15.8758C19.2706 17.025 18.3909 18.0191 17.3157 18.7799H17.6143C19.4585 18.7799 21.2271 18.0642 22.5311 16.7903C23.8352 15.5164 24.5678 13.7886 24.5678 11.9871C24.5678 10.1855 23.8352 8.45773 22.5311 7.18384C21.2271 5.90994 19.4585 5.19427 17.6143 5.19427V5.20905Z"
				fill="#54C5D3"
			/>
			<path
				d="M20.8605 12.1643C20.8171 12.9921 20.4444 13.7707 19.8218 14.334C19.1993 14.8972 18.3761 15.2006 17.5278 15.1794C16.6795 15.1582 15.8733 14.8141 15.281 14.2205C14.6887 13.6269 14.3572 12.8308 14.3572 12.0018C14.3572 11.1729 14.6887 10.3768 15.281 9.78316C15.8733 9.18956 16.6795 8.84549 17.5278 8.8243C18.3761 8.80312 19.1993 9.10649 19.8218 9.66974C20.4444 10.233 20.8171 11.0116 20.8605 11.8394C20.8345 10.5428 20.5008 9.26977 19.8857 8.12052C19.2706 6.97127 18.3909 5.97716 17.3157 5.21643C15.5296 5.29785 13.8442 6.0483 12.6097 7.31189C11.3751 8.57549 10.6863 10.2551 10.6863 12.0018C10.6863 13.7486 11.3751 15.4282 12.6097 16.6918C13.8442 17.9554 15.5296 18.7058 17.3157 18.7873C18.3909 18.0265 19.2706 17.0324 19.8857 15.8832C20.5008 14.7339 20.8345 13.4609 20.8605 12.1643Z"
				fill="url(#paint1_radial_20_1061)"
			/>
			<path
				d="M46.1085 9.80896C46.1101 10.4045 45.9899 10.9944 45.7549 11.544C45.52 12.0935 45.175 12.5917 44.7402 13.0092C44.3054 13.4267 43.7896 13.7551 43.2231 13.9752C42.6566 14.1953 42.0508 14.3025 41.4413 14.2907H39.0416V18.6728H36.3509V5.33088H41.4413C42.0507 5.31858 42.6564 5.42539 43.2229 5.64506C43.7893 5.86473 44.3052 6.19281 44.74 6.61002C45.1748 7.02723 45.5199 7.52515 45.7549 8.0745C45.9899 8.62386 46.1101 9.21357 46.1085 9.80896ZM43.4329 9.80896C43.4463 9.54654 43.4046 9.28422 43.3102 9.03817C43.2159 8.79211 43.071 8.56755 42.8843 8.37833C42.6977 8.18912 42.4734 8.03925 42.2252 7.93799C41.977 7.83673 41.7102 7.78623 41.4413 7.78958H39.0416V11.8283H41.4413C41.7098 11.8301 41.9758 11.7785 42.2233 11.6767C42.4707 11.5749 42.6943 11.425 42.8806 11.2361C43.0669 11.0472 43.2119 10.8233 43.3069 10.578C43.4018 10.3326 43.4447 10.071 43.4329 9.80896Z"
				fill="#010101"
			/>
			<path
				d="M52.994 12.0018C52.997 10.6315 53.4158 9.29279 54.1975 8.15497C54.9793 7.01714 56.0888 6.13128 57.3859 5.6094C58.6829 5.08753 60.1093 4.95306 61.4845 5.22299C62.8598 5.49293 64.1223 6.15515 65.1123 7.12594C66.1024 8.09672 66.7756 9.33248 67.0468 10.677C67.318 12.0215 67.175 13.4143 66.6359 14.6795C66.0969 15.9446 65.1859 17.0252 64.0183 17.7846C62.8506 18.544 61.4787 18.9482 60.0759 18.946C59.1416 18.9534 58.2151 18.7787 57.3507 18.432C56.4863 18.0854 55.7014 17.5737 55.0419 16.9271C54.3824 16.2804 53.8616 15.5117 53.5099 14.666C53.1583 13.8203 52.9829 12.9146 52.994 12.0018ZM64.4899 12.0018C64.4802 11.1511 64.2131 10.3222 63.7223 9.61947C63.2314 8.91672 62.5387 8.37152 61.7313 8.0525C60.924 7.73349 60.0381 7.65491 59.1851 7.82667C58.3321 7.99843 57.5503 8.41284 56.9379 9.01774C56.3255 9.62264 55.9099 10.391 55.7435 11.2261C55.5772 12.0612 55.6674 12.9257 56.0029 13.7108C56.3384 14.496 56.9041 15.1666 57.6289 15.6383C58.3536 16.11 59.205 16.3617 60.0759 16.3618C60.6629 16.3731 61.2462 16.2676 61.7901 16.0519C62.3341 15.8362 62.8275 15.5146 63.2404 15.1068C63.6532 14.6991 63.9769 14.2135 64.1917 13.6798C64.4065 13.146 64.5079 12.5751 64.4899 12.0018Z"
				fill="#010101"
			/>
			<path
				d="M73.7373 15.6419L76.0388 14.3276C76.5414 15.5681 77.4824 16.3618 79.1867 16.3618C80.808 16.3618 81.3748 15.6973 81.3748 14.9516C81.3748 13.9622 80.4565 13.5819 78.4271 13.0097C76.3411 12.4153 74.3117 11.5588 74.3117 9.02262C74.3117 6.48639 76.4545 5.05769 78.7975 5.05769C79.8223 5.03091 80.8324 5.30088 81.7006 5.83364C82.5687 6.3664 83.2561 7.13813 83.6763 8.05169L81.4088 9.32904C80.9213 8.3175 80.1806 7.61237 78.7937 7.61237C77.66 7.61237 76.9986 8.1846 76.9986 8.94879C76.9986 9.71298 77.5239 10.204 79.5949 10.8168C81.7603 11.485 84.0617 12.1864 84.0617 14.9146C84.0617 17.4102 82.0135 18.9349 79.1074 18.9349C76.3109 18.946 74.478 17.6244 73.7373 15.6419Z"
				fill="#010101"
			/>
			<path
				d="M94.2615 5.33087V18.6728H91.5707V5.33087H94.2615Z"
				fill="#010101"
			/>
			<path
				d="M111.687 7.84495H107.998V18.6728H105.308V7.84495H101.638V5.33087H111.687V7.84495Z"
				fill="#010101"
			/>
			<path
				d="M121.735 5.33087V18.6728H119.045V5.33087H121.735Z"
				fill="#010101"
			/>
			<path
				d="M128.916 5.33087H131.841L135.177 15.5865L138.495 5.33087H141.443L136.836 18.6728H133.503L128.916 5.33087Z"
				fill="#010101"
			/>
			<path
				d="M157.055 16.1588V18.6728H148.605V5.33087H156.956V7.84495H151.288V10.6876H156.469V13.1647H151.295V16.1588H157.055Z"
				fill="#010101"
			/>
			<path
				d="M163.785 5.33087H164.919L169.427 17.3586L173.962 5.33087H175.073L169.983 18.6728H168.887L163.785 5.33087Z"
				fill="#010101"
			/>
			<path
				d="M183.92 5.33087V18.6728H182.888V5.33087H183.92Z"
				fill="#010101"
			/>
			<path
				d="M192.155 15.7379L193.054 15.221C193.519 16.8233 194.906 17.9123 197.151 17.9123C199.395 17.9123 200.586 16.7679 200.586 15.2432C200.586 13.641 199.142 13.0281 197.052 12.3821C194.785 11.6437 192.721 11.0309 192.721 8.69036C192.721 6.49746 194.535 5.10568 196.856 5.10568C197.796 5.07816 198.721 5.33366 199.508 5.83754C200.294 6.34142 200.902 7.06929 201.251 7.92248L200.374 8.3987C200.114 7.70015 199.633 7.10035 199.002 6.6857C198.37 6.27104 197.62 6.06294 196.86 6.09137C194.97 6.09137 193.78 7.17674 193.78 8.67559C193.78 10.2778 195.163 10.7725 197.117 11.4038C199.535 12.1827 201.651 12.9285 201.651 15.2321C201.651 17.4472 199.875 18.8943 197.162 18.8943C194.592 18.8832 192.778 17.6244 192.155 15.7379Z"
				fill="#010101"
			/>
			<path
				d="M210.967 5.33087V18.6728H209.943V5.33087H210.967Z"
				fill="#010101"
			/>
			<path
				d="M219.432 12.0019C219.431 10.6398 219.844 9.30813 220.618 8.17531C221.392 7.0425 222.493 6.15945 223.781 5.63787C225.069 5.1163 226.486 4.97964 227.854 5.24519C229.222 5.51073 230.478 6.16655 231.464 7.12967C232.449 8.09279 233.121 9.31993 233.393 10.6558C233.664 11.9918 233.525 13.3764 232.991 14.6347C232.457 15.8929 231.553 16.9682 230.393 17.7244C229.234 18.4807 227.87 18.884 226.476 18.8833C225.55 18.8886 224.631 18.7143 223.774 18.3704C222.917 18.0265 222.138 17.5198 221.483 16.8798C220.828 16.2397 220.309 15.4789 219.957 14.6416C219.605 13.8043 219.426 12.907 219.432 12.0019ZM232.489 12.0019C232.482 10.8416 232.124 9.7093 231.459 8.74778C230.794 7.78626 229.853 7.0386 228.754 6.59914C227.654 6.15967 226.447 6.04809 225.282 6.27846C224.118 6.50883 223.05 7.07084 222.213 7.89358C221.375 8.71632 220.806 9.76293 220.577 10.9014C220.348 12.0398 220.469 13.2191 220.925 14.2904C221.381 15.3617 222.151 16.2771 223.139 16.921C224.127 17.565 225.288 17.9086 226.476 17.9086C227.27 17.9121 228.056 17.7614 228.789 17.4654C229.522 17.1694 230.188 16.7339 230.748 16.1843C231.307 15.6346 231.749 14.9818 232.048 14.2638C232.347 13.5458 232.497 12.7769 232.489 12.0019Z"
				fill="#010101"
			/>
			<path
				d="M252 5.33087V18.6728H251.082L243.025 7.19889V18.6728H241.989V5.33087H242.889L250.965 16.8048V5.33087H252Z"
				fill="#010101"
			/>
		</g>
		<defs>
			<radialGradient
				id="paint0_radial_20_1061"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(19.0504 19.0457) scale(10.1355 9.90125)"
			>
				<stop stop-color="#0A5163" />
				<stop offset="0.5" stop-color="#2E8A9A" />
				<stop offset="1" stop-color="#54C5D3" />
			</radialGradient>
			<radialGradient
				id="paint1_radial_20_1061"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(21.6768 7.51103) scale(8.14013 6.12305)"
			>
				<stop stop-color="#0A5163" />
				<stop offset="0.03" stop-color="#0D5467" />
				<stop offset="0.61" stop-color="#3796A6" />
				<stop offset="1" stop-color="#54C5D3" />
			</radialGradient>
			<clipPath id="clip0_20_1061">
				<rect width="252" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export const LogoWhite = ({ className }) => (
	<svg
		className={className}
		viewBox="0 0 253 26"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_266_6)">
			<path
				d="M24.7784 13C24.7784 12.8799 24.7784 12.757 24.7784 12.6397C24.6837 9.38651 23.3127 6.30117 20.9617 4.05055C18.6107 1.79994 15.4685 0.564826 12.2143 0.612151C8.96001 0.659477 5.85506 1.98544 3.57049 4.30347C1.28593 6.6215 0.00524902 9.74541 0.00524902 13C0.00524902 16.2546 1.28593 19.3785 3.57049 21.6966C5.85506 24.0146 8.96001 25.3406 12.2143 25.3879C15.4685 25.4352 18.6107 24.2001 20.9617 21.9495C23.3127 19.6989 24.6837 16.6135 24.7784 13.3603C24.7784 13.2402 24.7784 13.1201 24.7784 13ZM21.0327 13.1573C20.9887 14.0121 20.6126 14.8159 19.9845 15.3974C19.3563 15.9788 18.5259 16.2919 17.6703 16.2699C16.8146 16.2479 16.0014 15.8926 15.404 15.2796C14.8066 14.6666 14.4722 13.8445 14.4722 12.9886C14.4722 12.1326 14.8066 11.3105 15.404 10.6976C16.0014 10.0846 16.8146 9.72924 17.6703 9.70724C18.5259 9.68523 19.3563 9.99833 19.9845 10.5798C20.6126 11.1613 20.9887 11.9651 21.0327 12.8199C21.0327 12.8742 21.0327 12.9314 21.0327 12.9886C21.0327 13.0458 21.0327 13.1115 21.0327 13.1573ZM12.3863 21.6407C11.0179 21.6467 9.66755 21.3281 8.44618 20.7109C7.2248 20.0937 6.16723 19.1956 5.36032 18.0904C4.55341 16.9852 4.02018 15.7043 3.8044 14.353C3.58861 13.0016 3.69644 11.6184 4.11903 10.3168C4.54162 9.01526 5.26691 7.8325 6.23534 6.86565C7.20378 5.89881 8.38774 5.17547 9.69 4.75503C10.9923 4.33459 12.3757 4.22903 13.7266 4.44704C15.0776 4.66504 16.3576 5.2004 17.4615 6.00912C15.6526 6.08223 13.9421 6.85224 12.6881 8.15798C11.434 9.46371 10.7337 11.2039 10.7337 13.0143C10.7337 14.8247 11.434 16.5649 12.6881 17.8706C13.9421 19.1764 15.6526 19.9464 17.4615 20.0195C15.9863 21.0871 14.2102 21.6588 12.3892 21.6521L12.3863 21.6407Z"
				fill="white"
			/>
		</g>
		<path
			d="M46.2798 10.7097C46.281 11.3214 46.16 11.9272 45.9241 12.4915C45.6881 13.0559 45.3419 13.5674 44.9056 13.9963C44.4694 14.4251 43.952 14.7625 43.3837 14.9888C42.8154 15.2151 42.2076 15.3257 41.5961 15.3141H39.2145V19.8231H36.5154V6.10532H41.6278C42.2368 6.09795 42.8411 6.21167 43.4057 6.43986C43.9703 6.66805 44.484 7.00616 44.9168 7.43456C45.3496 7.86295 45.693 8.37308 45.927 8.93532C46.161 9.49756 46.2809 10.1007 46.2798 10.7097ZM43.5886 10.7097C43.6016 10.4408 43.5596 10.1722 43.4651 9.9202C43.3705 9.6682 43.2256 9.43815 43.039 9.24416C42.8525 9.05017 42.6282 8.8963 42.3801 8.79202C42.132 8.68774 41.8652 8.63524 41.5961 8.63772H39.2145V12.7896H41.5961C41.8652 12.7898 42.1317 12.7355 42.3793 12.63C42.6269 12.5244 42.8506 12.3698 43.0368 12.1754C43.223 11.9811 43.3679 11.751 43.4628 11.499C43.5576 11.2471 43.6004 10.9786 43.5886 10.7097Z"
			fill="white"
		/>
		<path
			d="M53.1943 12.9718C53.1943 11.5588 53.6133 10.1774 54.3984 9.00247C55.1835 7.82753 56.2993 6.91177 57.6049 6.37101C58.9104 5.83024 60.3469 5.68876 61.7329 5.96444C63.1188 6.24012 64.3919 6.92058 65.3911 7.91979C66.3903 8.91899 67.0707 10.1921 67.3464 11.578C67.6221 12.9639 67.4806 14.4005 66.9398 15.706C66.3991 17.0115 65.4833 18.1274 64.3084 18.9124C63.1335 19.6975 61.7521 20.1166 60.339 20.1166C59.3972 20.1294 58.4624 19.9533 57.5897 19.5988C56.7171 19.2443 55.9244 18.7185 55.2584 18.0525C54.5924 17.3865 54.0666 16.5937 53.7121 15.7211C53.3575 14.8485 53.1815 13.9137 53.1943 12.9718ZM64.7449 12.9718C64.7339 12.0978 64.4646 11.2466 63.9709 10.5253C63.4772 9.80405 62.7812 9.24496 61.9704 8.91843C61.1596 8.5919 60.2703 8.51252 59.4145 8.69031C58.5587 8.8681 57.7747 9.2951 57.161 9.91756C56.5474 10.54 56.1316 11.3301 55.966 12.1883C55.8005 13.0466 55.8925 13.9346 56.2305 14.7407C56.5686 15.5467 57.1376 16.2347 57.8658 16.7181C58.5941 17.2015 59.4491 17.4586 60.3231 17.4571C60.9119 17.4671 61.4966 17.3574 62.0418 17.1347C62.587 16.912 63.0812 16.5809 63.4947 16.1616C63.9081 15.7422 64.2321 15.2432 64.447 14.695C64.6619 14.1467 64.7633 13.5605 64.7449 12.9718Z"
			fill="white"
		/>
		<path
			d="M74.0172 16.703L76.3273 15.3534C76.5454 16.0079 76.977 16.5702 77.5527 16.9502C78.1285 17.3302 78.8152 17.5059 79.5027 17.4492C81.0904 17.4492 81.6937 16.7665 81.6937 15.9964C81.6937 14.9803 80.7729 14.5913 78.7327 14.0039C76.6924 13.4164 74.6046 12.5114 74.6046 9.90758C74.6046 7.30373 76.756 5.8351 79.1058 5.8351C80.1344 5.80596 81.1486 6.08222 82.0203 6.629C82.8921 7.17579 83.5823 7.96859 84.0039 8.90732L81.7335 10.2172C81.5356 9.68495 81.1754 9.22841 80.7039 8.91214C80.2323 8.59587 79.6733 8.43588 79.1058 8.45481C77.9706 8.45481 77.3037 9.04227 77.3037 9.82819C77.3037 10.6141 77.8356 11.1222 79.9075 11.7493C81.9795 12.3765 84.4008 13.1624 84.4008 15.9647C84.4008 18.5288 82.3368 20.0927 79.4233 20.0927C76.5099 20.0927 74.7634 18.7432 74.0172 16.703Z"
			fill="white"
		/>
		<path d="M94.6257 6.10547V19.8233H91.9266V6.10547H94.6257Z" fill="white" />
		<path
			d="M112.122 8.69342H108.407V19.8074H105.7V8.69342H102.056V6.10547H112.146L112.122 8.69342Z"
			fill="white"
		/>
		<path d="M122.212 6.10547V19.8233H119.521V6.10547H122.212Z" fill="white" />
		<path
			d="M129.42 6.10547H132.358L135.708 16.6479L139.042 6.10547H141.995L137.375 19.8233H134.025L129.42 6.10547Z"
			fill="white"
		/>
		<path
			d="M157.674 17.2353V19.8233H149.187V6.10547H157.57V8.69342H151.894V11.6148H157.086V14.1631H151.894V17.2353H157.674Z"
			fill="white"
		/>
		<path
			d="M164.429 6.10547H165.565L170.09 18.4658L174.638 6.10547H175.758L170.637 19.8233H169.534L164.429 6.10547Z"
			fill="white"
		/>
		<path d="M184.617 6.10547V19.8233H183.577V6.10547H184.617Z" fill="white" />
		<path
			d="M192.913 16.8062L193.818 16.2743C194.286 17.9176 195.676 19.0369 197.93 19.0369C200.185 19.0369 201.375 17.862 201.375 16.2981C201.375 14.7342 199.931 14.0198 197.835 13.3529C195.541 12.6146 193.485 11.9637 193.485 9.57416C193.485 7.31961 195.302 5.89066 197.636 5.89066C198.577 5.86303 199.504 6.12445 200.292 6.63969C201.08 7.15494 201.691 7.89925 202.042 8.77236L201.161 9.25662C200.902 8.53706 200.421 7.91849 199.788 7.49036C199.154 7.06223 198.401 6.84675 197.636 6.87505C195.739 6.87505 194.54 7.99438 194.54 9.51858C194.54 11.0428 195.93 11.6779 197.89 12.3209C200.32 13.1148 202.439 13.9086 202.439 16.2902C202.439 18.6717 200.653 20.0531 197.93 20.0531C195.366 20.0372 193.54 18.7432 192.913 16.8062Z"
			fill="white"
		/>
		<path d="M211.799 6.10547V19.8233H210.767V6.10547H211.799Z" fill="white" />
		<path
			d="M220.341 12.9717C220.341 11.5727 220.756 10.2052 221.533 9.04201C222.31 7.87882 223.415 6.97221 224.707 6.43685C226 5.9015 227.422 5.76143 228.794 6.03435C230.166 6.30728 231.426 6.98094 232.415 7.97015C233.405 8.95937 234.078 10.2197 234.351 11.5918C234.624 12.9639 234.484 14.386 233.949 15.6785C233.413 16.971 232.507 18.0757 231.344 18.8529C230.18 19.6301 228.813 20.045 227.414 20.045C226.484 20.0502 225.561 19.8709 224.701 19.5173C223.84 19.1637 223.059 18.6428 222.401 17.985C221.743 17.3271 221.222 16.5453 220.868 15.6847C220.515 14.8242 220.335 13.902 220.341 12.9717ZM233.447 12.9717C233.439 11.7801 233.079 10.6176 232.411 9.63067C231.744 8.64373 230.799 7.87659 229.695 7.42602C228.592 6.97546 227.38 6.86163 226.213 7.09889C225.045 7.33615 223.974 7.91388 223.134 8.75921C222.294 9.60454 221.723 10.6796 221.494 11.8489C221.264 13.0181 221.386 14.2292 221.844 15.3293C222.302 16.4294 223.075 17.3694 224.066 18.0306C225.057 18.6918 226.222 19.0447 227.414 19.0447C228.207 19.0437 228.993 18.8854 229.725 18.5788C230.457 18.2723 231.121 17.8237 231.678 17.2589C232.235 16.6941 232.675 16.0244 232.972 15.2885C233.269 14.5526 233.417 13.7652 233.408 12.9717H233.447Z"
			fill="white"
		/>
		<path
			d="M253 6.10547V19.8233H252.095L244.006 8.02659V19.8233H242.966V6.10547H243.871L251.984 17.9022V6.10547H253Z"
			fill="white"
		/>
		<defs>
			<clipPath id="clip0_266_6">
				<rect
					width="24.7784"
					height="24.7784"
					fill="white"
					transform="translate(0 0.61084)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export const Play = ({ className }) => (
	<svg
		className={className}
		viewBox="0 0 58 67"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M55.9375 36.9712C58.6208 35.4349 58.6208 31.5651 55.9375 30.0288L5.98756 1.42836C3.32092 -0.0985045 0 1.82678 0 4.89962V62.1004C0 65.1732 3.32093 67.0985 5.98757 65.5716L55.9375 36.9712Z"
			fill="#FF8B59"
		/>
	</svg>
);

export const Pause = ({ className }) => (
	<svg
		className={className}
		viewBox="0 0 22 27"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			x="8"
			width="27"
			height="8"
			rx="2"
			transform="rotate(90 8 0)"
			fill="#51C7D4"
		/>
		<rect
			x="22"
			width="27"
			height="8"
			rx="2"
			transform="rotate(90 22 0)"
			fill="#51C7D4"
		/>
	</svg>
);

export const Fullscreen = ({ className }) => (
	<svg
		className={className}
		width="25"
		height="25"
		viewBox="0 0 25 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_20_1489)">
			<path
				d="M2.07764 9.93499V2.07764H9.93499"
				stroke="#51C7D4"
				stroke-width="5"
				stroke-miterlimit="10"
			/>
			<path
				d="M9.93499 22.9223H2.07764V14.9894"
				stroke="#51C7D4"
				stroke-width="5"
				stroke-miterlimit="10"
			/>
			<path
				d="M22.9223 14.9894V22.9223H15.0649"
				stroke="#51C7D4"
				stroke-width="5"
				stroke-miterlimit="10"
			/>
			<path
				d="M15.0649 2.07764H22.9223V9.93499"
				stroke="#51C7D4"
				stroke-width="5"
				stroke-miterlimit="10"
			/>
		</g>
		<defs>
			<clipPath id="clip0_20_1489">
				<rect width="25" height="25" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export const Close = ({ className }) => (
	<svg
		className={className}
		width="15"
		height="16"
		viewBox="0 0 15 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<line
			x1="0.353553"
			y1="0.646447"
			x2="14.4957"
			y2="14.7886"
			stroke="black"
		/>
		<line
			y1="-0.5"
			x2="20"
			y2="-0.5"
			transform="matrix(-0.707107 0.707107 0.707107 0.707107 15 1)"
			stroke="black"
		/>
	</svg>
);
