import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { mobile, navBreakpoint, tablet, desktop } from "../styles/global";

const Hamburger = ({ menuOpen, setMenuOpen }) => {
	const hamburgerCss = css`
		display: none;

		${navBreakpoint} {
			display: block;

			width: 15px;
			height: 13px;
			transition: opacity 0.5s;
			position: relative;
			// margin-right: auto;
			pointer-events: all;
			cursor: pointer;
			&:focus {
				outline: none;
			}
			&:after {
				content: "";
				display: block;
				position: absolute;
				top: -20px;
				right: 0;
				left: 0;
				bottom: -20px;
			}
			div {
				height: 1px;
				/* fix android menu line thicknesses */
				width: 100%;
				background-color: var(--black);
				position: absolute;
				transition: width 0.3s cubic-bezier(0.215, 0.61, 0.355, 1),
					transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1),
					background-color 0.3s;
				right: 0px;
				border-radius: 10px;
				&:nth-of-type(1) {
					top: 0px;
					transition-delay: ${menuOpen ? "0s" : "0.3s"},
						${menuOpen ? "0s" : "0.3s"}, 0s;
					width: ${menuOpen ? "0px" : "100%"};
					background-color: var(--black);
				}
				&:nth-of-type(2),
				&:nth-of-type(3) {
					top: 6.5px;
					// right: ${menuOpen ? "-3px" : "0px"};
					background-color: var(--black);
					width: ${menuOpen ? "133%" : "100%"};
					transition-delay: ${menuOpen ? "0.3s" : "0s"},
						${menuOpen ? "0.3s" : "0s"}, 0s;
				}
				&:nth-of-type(2) {
					transform: ${menuOpen ? "rotate(45deg)" : "rotate(0deg)"};
					transform-origin: 11px 3px;
				}
				&:nth-of-type(3) {
					transform: ${menuOpen ? "rotate(-45deg)" : "rotate(0deg)"};
					transform-origin: 11px -2px;
				}
				&:nth-of-type(4) {
					top: 13px;
					transition-delay: ${menuOpen ? "0s" : "0.3s"},
						${menuOpen ? "0s" : "0.3s"}, 0s;
					width: ${menuOpen ? "0px" : "100%"};
					background-color: var(--black);
				}
			}
		}
	`;

	return (
		<button
			css={hamburgerCss}
			onClick={() => {
				setMenuOpen(!menuOpen);
			}}
			role="button"
			tabIndex={0}
		>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</button>
	);
};

Hamburger.propTypes = {
	menuOpen: PropTypes.bool,
};

Hamburger.defaultProps = {};

export default Hamburger;
